import firebaseApp from "./app";

const firebase = {
  /**
   * @param {string} email
   * @param {string} password
   */
  async login(email, password) {
    try {
      const response = await firebaseApp.signInWithEmailAndPassword(
        email,
        password,
      );

      return response.user;
    } catch (error) {
      if (checkIsFirebaseErrorWithCode(error))
        throw new FirebaseError(error.message, error.code, error.name);

      throw error;
    }
  },

  async logout() {
    firebaseApp.signOut();
  },

  /**
   * @param {string} email
   * @param {string} password
   */
  async register(email, password) {
    try {
      return await firebaseApp.createUserWithEmailAndPassword(email, password);
    } catch (error) {
      if (checkIsFirebaseErrorWithCode(error))
        throw new FirebaseError(error.message, error.code, error.name);
      throw error;
    }
  },

  async delete() {
    try {
      await firebaseApp.currentUser?.delete();
    } catch (error) {
      if (checkIsFirebaseErrorWithCode(error))
        throw new FirebaseError(error.message, error.code, error.name);
      throw error;
    }
  },

  async getAccessToken(){
    try {
      return await firebaseApp.currentUser?.getIdToken() ?? null
    } catch (error) {
      if (checkIsFirebaseErrorWithCode(error))
        throw new FirebaseError(error.message, error.code, error.name);
      throw error;
    }
  }
};

class FirebaseError extends Error {
  code;
  name;
  constructor(message, code, name) {
    super(message);
    this.code = code;
    this.name = name;
  }
}

function checkIsFirebaseErrorWithCode(error) {
  return (
    error instanceof Error &&
    typeof error === "object" &&
    error &&
    "code" in error &&
    typeof error.code === "string"
  );
}

export default firebase;
export { FirebaseError };
