import React, { useEffect, useState } from 'react';
import '../css/HomeCategory.css';
import CategoryUpper from './CategoryUpper';
import { useLocation } from 'react-router-dom';
import Home from '../components/Home';

function HomeCategory() {
  const location = useLocation();
  const cName = location?.state?.category;
  const cId = location?.state?.id;
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setRefresh(false);
    setTimeout(() => {
      setRefresh(true);
    }, [500]);
  }, [cId]);
  const RunAgain = () => {
    return (
      <div className='categoryBody'>
        <CategoryUpper cName={cName} />
      </div>
    );
  };
  return cId !== undefined && cName !== undefined ? (
    refresh && <RunAgain />
  ) : (
    <div>
      <Home />
    </div>
  );
}

export default HomeCategory;
