
import { createTheme } from '@mui/material';
import { PRIMARY, SECONDARY, SOFT, SUCCESS } from './colors';

const theme = createTheme({
  palette: {
    primary: { main: PRIMARY },
    secondary: { main: SECONDARY },
    soft: { main: SOFT },
    success: { main: SUCCESS },
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
  },
});

export default theme;
