//main slots
export const mainSlot1 = "Slot 01";
export const mainSlot2 = "Slot 02";
//Second Slots
export const secondSlot1 = "Slot 01";
export const secondSlot2 = "Slot 02";
export const secondSlot3 = "Slot 03";
export const secondSlot4 = "Slot 04";
export const secondSlot5 = "Slot 05";
//Third Slots
export const thirdSlot1 = "Slot 01";
export const thirdSlot2 = "Slot 02";
export const thirdSlot3 = "Slot 03";
export const thirdSlot4 = "Slot 04";
export const thirdSlot5 = "Slot 05";
export const thirdSlot6 = "Slot 06";
export const thirdSlot7 = "Slot 07";
