import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

import { useAuthModalContext } from "../../context/authModal";
import { useAuthContext } from "../../context/auth";

import Axios from "../Axios";

import { UserQuery } from "../../server/query";

function RegisterBox() {
  const { user } = useAuthContext();
  const { openRegisterModal } = useAuthModalContext();
  const [isSubscribed, setIsSubscribed] = useState(false);

  useEffect(() => {
    if (user)
      (async () => {
        const response = await Axios({ query: UserQuery });

        if (response.data.errors) return undefined;
        setIsSubscribed(response.data.user.isSubscribed);
      })();
  }, [user]);

  console.log(isSubscribed);

  return (
    <Box
      component="section"
      bgcolor={({ palette }) => palette.grey["300"]}
      px={3}
      py={4}
      borderRadius={2}
    >
      <Typography
        variant="h6"
        component="h2"
        id="registerFormTitle"
        className="font-mont"
      >
        {getRegisterBoxTitle(user !== null)}
      </Typography>

      <Typography mb={2} variant="body2" component="p" className="font-mont">
        {getLoginBoxDescription(user !== null, isSubscribed)}
      </Typography>

      <form>
        <Box display="flex" gap={2} flexDirection="column">
          <Button
            sx={{ maxWidth: "200px", mx: "auto", display: "block" }}
            variant="contained"
            disableElevation
            fullWidth
            onClick={openRegisterModal}
          >
            Register
          </Button>
        </Box>
      </form>
    </Box>
  );
}

/** @param {boolean} isLogged */
function getRegisterBoxTitle(isLogged) {
  return isLogged ? (
    <>Thank you for staying connected with us!</>
  ) : (
    <>Stay Connected</>
  );
}

/**
 * @param {boolean} isLogged
 * @param {boolean} isSubscribed
 */
function getLoginBoxDescription(isLogged, isSubscribed) {
  if (!isLogged) {
    return <>Get instant notification when new articles published.</>;
  }

  return isSubscribed ? (
    <>
      Thrilled to have you on board! <br />
      Get ready for a journey into insightful articles.
    </>
  ) : (
    <>
      Missed our latest articles? <br />
      Subscribe now and dive into a sea of knowledge!
    </>
  );
}

export default RegisterBox;
