import React from "react";

import { Route } from "react-router-dom";

import { useAuthContext } from "../context/auth";

function ProtectedRoute({ component: Component, path }) {
  const { user } = useAuthContext();
  if (user === null) return null;

  return <Route path={path} component={Component} />;
}

export default ProtectedRoute;
