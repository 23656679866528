const UserQuery = /* GraphQL */ `
  {
    user {
      id
      firstName
      lastName
      email
      mobileNumber
      occupation
      education
      address
      isSubscribed
    }
  }
`;

const SlotsWithArticleQuery = /* GraphQL */ `
  query {
    slots {
      id
      slot
      place
      artical {
        id
        categoryId
        categoryName
        slotId
        title
        subTitle
        editer
        volume
        main
        slotNo
        homePlace
        titleImage
        pharagraph01
        pharagraph02
        pharagraph03
        createdAt
        updatedAt
      }
    }
  }
`;

const ArticlesQuery = /* GraphQL */ `
  query {
    articals {
      id
      categoryId
      categoryName
      slotId
      title
      subTitle
      editer
      volume
      main
      slotNo
      homePlace
      titleImage
      pharagraph01
      pharagraph02
      pharagraph03
      createdAt
      updatedAt
    }
  }
`;

const FindArticlesByCategory = ({ categoryName }) => /* GraphQL */ `
  query {
    articals(where: { categoryName:"${categoryName}" }) {
      id
      categoryId
      categoryName
      slotId
      title
      subTitle
      editer
      volume
      main
      slotNo
      homePlace
      titleImage
      subTitlteImg
      pharagraph01
      pharagraph02
      pharagraph03
      pharagraph04
      pharagraph05
      pharagraph06
      pharagraph07
      pharagraph08
      imageUrl01
      imageUrl02
      imageUrl03
      imageUrl04
      imageUrl05
      imageUrl06
      imageUrl07
      imageUrl08
      createdAt
      updatedAt
    }
  }
`;

const CategoriesQuery = `query {
  categories {
    id
    category
  }
}`;

export {
  UserQuery,
  SlotsWithArticleQuery,
  ArticlesQuery,
  CategoriesQuery,
  FindArticlesByCategory,
};
