import React from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import DIC from "../images/dic.png";
import profile from "../images/profile.png";
import SIGN from "../images/sign.png";

function Editorial({ open, onClose }) {
  const style_common = {
    textAlign: "left",
    lineHeight: 1.5,
    fontFamily: "Arial, Helvetica, sans-serif",
    fontSize: "13pt",
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <DialogActions>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogActions>

      <DialogContent sx={{ p: { xs: 6, md: 10 } }}>
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            src={profile}
            width={130}
            height={130}
            style={{ objectFit: "contain", maxWidth: "60%", height: "auto" ,alignSelf: "flex-start" , padding:"2"}}
          />
          <br />
          
          <img
            src={DIC}
            width={250}
            height={120}
            style={{ objectFit: "contain", maxWidth: "75%", height: "auto" }}
          />
          <br />
          <p
            style={{
              ...style_common,
              fontWeight: 700,
              textAlign: "center",
              font: "bold",
              fontSize: "14px",
              maxWidth: "40%"
              
            }}
          >
            or <br />
            Knowledge is our sword we fight with
          </p>
          <br />
          
        </div>
        <br />
        <br />
        <div
          style={{
            width: "100%",
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          
          <p style={{style_common, fontWeight: 700}} >Editorial for the 7th edition</p>
        </div>
        <br />
        <br />

        <p style={style_common}>
        Dear reader, and above all, dear young reader, the seventh issue of &quot;respect&quot; has been
        finalised after long discussions about the content.
        </p>
        <br />

        <p style={style_common}>
        This year marks the 300th anniversary of the birth of Immanuel Kant, one of the
        greatest philosophers of the Enlightenment. With a proper understanding of Kant, he
        reaches helpfully into all areas of our lives. That is why he will continue to accompany
        us in the next issues. I have therefore selected a somewhat longer lecture for you by
        one of the most distinguished economics professors at the University of Bamberg, who
        explains how Kant&#39;s a priori and a posteriori definition also influence the basis of liberal
        economic theory.
        </p>
        
        <br />

        <p style={style_common}>
        It is my impression that for many years, economic policy has been pursued in a
        &quot;landlord style&quot;. It has often been about self-promotion, short-term and superficial
        decisions and not about long-term benefits. The causes are manifold, and often lie not
        only in an economic policy that has not matured, but also in our own behaviour. That is
        why I have decided to translate the book by the German economics professor and
        &quot;father of the German economic miracle&quot; Prosperity for All and publish it here. The book
        cannot be translated 1:1, because the main difference is that Germany is an
        industrialised nation and we have so far successfully resisted building up an industry.
        So our predicament has many fathers.
        </p>
        <br />
        <p style={style_common}>
        Furthermore, there is always room for philosophy, which I consider to be an essential
        pillar of the discipline of thought. An article on epistemology will appear in the next
        issue. No, not Kant, but based on the ancient Greek philosophers. Understanding
        epistemology will require a bit of brain acrobatics from us, it is time to raise the level of
        this magazine a bit and I would like to give you the opportunity to get a nerve-
        strengthening tonic from your trusted Ayurveda dealer as a precaution.
        </p>
        <br />
        <p style={style_common}>
        Dear readers, I leave you to read this 7th edition and enjoy it.
        </p>
        <br />

        <div>
          <p>Sincerely</p>
          <br />
          <img
            src={SIGN}
            width={100}
            height="auto"
            style={{ objectFit: "contain" }}
          />
          <br />
          <p>Editor</p>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default Editorial;
