import React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import respectLogoWhite from "../images/WhiteLogo.png";

import Editorial from "../Popup/Editorial";

import useBoolean from "../util/hook/useBoolean";

import "../css/NavBar.css";

function NavBar() {
  const currentDate = new Date().toLocaleDateString();
  const [isEditorialModalOpen, , openEditorialModal, closeEditorialModal] =
    useBoolean();

  return (
    <>
      <div className="Navbar">
        <div className="home_container">
          <img className="home_log" src={respectLogoWhite} alt="Respect Logo" />
          <p className="home_hContent">A further Opinion</p>
          <div>
            <Button
              onClick={openEditorialModal}
              variant="text"
              disableElevation
              color="inherit"
              sx={{ px: 6 }}
            >
              <Typography
                variant="h6"
                component="span"
                fontWeight="bold"
                sx={{
                  color: "white",
                  textDecoration: "underline",
                  textUnderlineOffset: 6,
                }}
              >
                Editorial
              </Typography>
            </Button>
          </div>
          <div className="content_h">
            <p className="home_date">Date : {currentDate}</p>
          </div>
        </div>
      </div>

      <Editorial onClose={closeEditorialModal} open={isEditorialModalOpen} />
    </>
  );
}

export default NavBar;
