import axios from "axios";
import firebase from "../service/firebase";

/** @typedef {{ errors?: Record<string, unknown>[]; data: any }} Response */

/**
 * @param {{ query: string; title?: string }} param
 * @returns {Promise<Response>}
 */
async function Axios({ query, title }) {
  const token = await firebase.getAccessToken();

  try {
    const result = await axios({
      method: "POST",
      url: process.env.REACT_APP_SERVER_URL,
      data: { query },
      headers: token ? { Authorization: `Bearer ${token}` } : undefined,
    });
    return result.data;
  } catch (error) {
    console.error(`${title} T&C`, error);
    throw error;
  }
}

export default Axios;
