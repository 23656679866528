import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";

import CloseIcon from "@material-ui/icons/Close";

import { yupResolver } from "@hookform/resolvers/yup";

import React, { useRef } from "react";

import { FormProvider, useForm } from "react-hook-form";

import Axios from "./../Axios";

import EmailStep, { schema as EmailStepSchema } from "./EmailStep";
import PasswordResetStep, {
  schema as PasswordResetStepSchema,
} from "./PasswordResetStep";
import EmailConfirmationStep, {
  schema as EmailConfirmationStepSchema,
} from "./EmailConfirmationStep";

import useMultiStepForm from "../../util/hook/useMultiStepForm";
import {
  confirmVerificationCode,
  sendPasswordResetEmail,
  updatePassword,
} from "../../server/mutation";
import { useToastContext } from "../../context/toast";

/**
 * @param {{
 *   open: boolean;
 *   onClose: () => void;
 * }} props
 */
function ForgotPassword({ open, onClose }) {
  const { displayToast } = useToastContext();
  const { CurrentStep, nextStep, schema, reset } = useMultiStepForm({
    formSteps: [EmailStep, EmailConfirmationStep, PasswordResetStep],
    schemas: [
      EmailStepSchema,
      EmailConfirmationStepSchema,
      PasswordResetStepSchema,
    ],
  });

  const form = useForm({
    defaultValues: {
      email: "",
      verificationCode: "",
      password: "",
      confirmPassword: "",
    },
    resolver: schema && yupResolver(schema),
  });

  const referenceId = useRef(/** @type {null | string} */ (null));

  function resetAndClose() {
    form.reset();
    reset();
    onClose();
  }

  async function onSubmit(data) {
    if (CurrentStep === EmailStep) {
      const query = sendPasswordResetEmail({ email: data.email });
      const response = await Axios({ query });

      if (response.errors) {
        displayToast({ message: "Something went wrong", mode: "error" });
        return undefined;
      }

      if (response.data.sendPasswordResetEmail.__typename === "FieldError") {
        const error = response.data.sendPasswordResetEmail;
        if (error.path === "email") {
          form.setError(error.path, error.message);
          return undefined;
        }
      }

      referenceId.current = response.data.sendPasswordResetEmail.referenceId;
    }

    if (CurrentStep === EmailConfirmationStep && referenceId.current) {
      const query = confirmVerificationCode({
        code: data.verificationCode,
        referenceId: referenceId.current,
      });
      const response = await Axios({ query });

      if (response.errors) {
        displayToast({ message: "Something went wrong", mode: "error" });
        return undefined;
      }

      if (response.data.confirmVerificationCode.success === false) {
        form.setError("verificationCode", { message: "Incorrect verification code." });
        return undefined;
      }
    }

    if (CurrentStep === PasswordResetStep && referenceId.current) {
      const query = updatePassword({
        referenceId: referenceId.current,
        password: data.password,
      });
      const response = await Axios({ query });

      if (response.errors) {
        displayToast({ message: "Something went wrong", mode: "error" });
        return undefined;
      }

      displayToast({ message: "Password reset successful.", mode: "success" });
      resetAndClose();
      return undefined;
    }
    nextStep();
  }

  return (
    <FormProvider {...form}>
      <Dialog open={open} onClose={resetAndClose} maxWidth="xs" fullWidth>
        <Stack
          px={3}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <DialogTitle sx={{ px: 0 }}>Reset Password</DialogTitle>
          <IconButton aria-label="close modal" onClick={resetAndClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <CurrentStep />
          </form>
        </DialogContent>
      </Dialog>
    </FormProvider>
  );
}

export default ForgotPassword;
