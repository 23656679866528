import React, { useState } from 'react';

import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

/** @param {Omit<import('@mui/material').TextFieldProps,"type"|"InputProps">} props */
function PasswordField(props) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const Icon = isPasswordVisible ? VisibilityOff : Visibility;

  function togglePasswordVisibility() {
    setIsPasswordVisible(currentState => !currentState);
  }

  return (
    <TextField
      type={isPasswordVisible ? 'text' : 'password'}
      placeholder='••••••••'
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={togglePasswordVisibility} aria-label='toggle password visibility'>
              <Icon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
}

export default PasswordField;
