import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import Loader from "./Loader.js";

import axios from "../libraries/axios.js";

import { CategoriesQuery } from "../server/query";

import "../css/CategoryNavbar.css";

function CategoryNavbar() {
  const [user, setUser] = useState();
  const [navLinks, setNavLinks] = useState([]);
  const [category, setCategory] = useState([]);
  const history = useHistory();
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    FetchCategory();
  }, []);

  const FetchCategory = async () => {
    try {
      setVisibility(true);
      await axios({
        method: "POST",
        data: { query: CategoriesQuery },
      })
        .then((res) => {
          const data = res.data;
          if (data != null) {
            const updateCategory = data.data.categories;
            const updateHello = updateCategory?.map((item) => {
              return {
                id: item.id,
                link: `/${`${item.category}`.toLowerCase()}`,
                title: `${item.category}`,
              };
            });
            setNavLinks(updateHello);
            setCategory(updateCategory);
            setVisibility(false);
          } else {
            alert(res.data.errors[0].message);
          }
        })
        .catch((error) => {
          console.error("Category FetcingError ", error);
          setVisibility(false);
        });
    } catch (err) {
      console.error("CategoryError", err);
      setVisibility(false);
    }
  };

  const Goto = (id, category, link) => {
    history.push({
      pathname: link,
      state: {
        id: id,
        category: category,
      },
    });
  };
  return (
    <div className="CategoryNavbar">
      <Loader visibility={visibility} />
      <div className="categoryBox">
        {navLinks?.map((item) => {
          return (
            <span
              key={item.id}
              className="link__span"
              onClick={() => Goto(item.id, item.title, item.link)}
            >
              {item.title}
            </span>
          );
        })}
      </div>
    </div>
  );
}

export default CategoryNavbar;
