import { useCallback, useState } from "react";

/**
 * @typedef {() => void} toggle This toggles the value between true and false
 *
 * @typedef {() => void} setTrue
 *
 * @typedef {() => void} setFalse
 * @param {boolean | undefined} initialValue
 * @returns {[boolean, toggle, setTrue, setFalse]}
 */
function useBoolean(initialValue = false) {
  const [boolean, setBoolean] = useState(initialValue);

  const toggle = useCallback(() => setBoolean((current) => !current), []);
  const setTrue = useCallback(() => setBoolean(true), []);
  const setFalse = useCallback(() => setBoolean(false), []);

  return [boolean, toggle, setTrue, setFalse];
}

export default useBoolean;
