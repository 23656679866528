import React, { createContext, useContext } from "react";

/**
 * @typedef {{
 *   nextStep: () => void;
 *   previousStep: () => void;
 *   openLoginModal: () => void;
 *   onClose: () => void;
 *   referenceId: React.MutableRefObject<string | null>;
 *   setTitle: React.Dispatch<React.SetStateAction<string>>;
 *   setDialogMaxWidth: React.Dispatch<
 *     React.SetStateAction<
 *       import("@material-ui/core/styles/createBreakpoints").Breakpoint
 *     >
 *   >;
 * }} ContextValue
 */

const Context = createContext(/** @type {ContextValue | null} */ (null));

/** @param {{ children: React.ReactNode; value: ContextValue }} param */
function FormContextProvider({ children, value }) {
  return <Context.Provider value={value}>{children}</Context.Provider>;
}

function useFormContext() {
  const context = useContext(Context);

  if (context === null)
    throw new Error("Component should be inside the auth context");

  return context;
}

export { FormContextProvider, useFormContext };
