import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import firebaseApp from "../service/firebase/app";

/** @typedef {{ uid: string } | null} User */

const authContext = createContext(
  /**
   * @type {{
   *   user: User | null;
   *   setUser: React.Dispatch<React.SetStateAction<User | null>>;
   * } | null}
   */ (null),
);

/** @param {{ children: React.ReactNode }} props */
function AuthProvider(props) {
  const [user, setUser] = useState(/** @type {User | null} */ (null));

  useEffect(() => {
    const unsubscribeAuthListener = firebaseApp.onAuthStateChanged((user) => {
      if (user === null) {
        setUser(null);
        return undefined;
      }

      setUser({ uid: user.uid });
    });

    return () => {
      unsubscribeAuthListener();
    };
  }, [firebaseApp]);

  const value = useMemo(() => ({ user, setUser }), [user]);

  return (
    <authContext.Provider value={value}>{props.children}</authContext.Provider>
  );
}

function useAuthContext() {
  const context = useContext(authContext);
  if (context === null)
    throw new Error("Component should be inside the auth context");

  return context;
}

export { AuthProvider, useAuthContext };
