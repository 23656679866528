import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";

import React from "react";

import { Controller, useFormContext } from "react-hook-form";

import * as yup from "yup";

import PasswordField from "../password-field";

function PasswordResetStep() {
  const form = useFormContext();

  return (
    <>
      <Stack gap={2} mb={3}>
        <FormControl sx={{ gap: 0.5 }}>
          <Box component="label" htmlFor="password">
            Password
          </Box>
          <Controller
            name="password"
            control={form.control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <PasswordField
                id="password"
                size="small"
                inputRef={ref}
                autoComplete="new-password"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </FormControl>

        <FormControl sx={{ gap: 0.5 }}>
          <Box component="label" htmlFor="confirmPassword">
            Confirm Password
          </Box>
          <Controller
            name="confirmPassword"
            control={form.control}
            render={({ field: { ref, ...field }, fieldState }) => (
              <PasswordField
                id="confirmPassword"
                size="small"
                inputRef={ref}
                autoComplete="new-password"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                {...field}
              />
            )}
          />
        </FormControl>
      </Stack>
      <Button
        fullWidth
        type="submit"
        variant="contained"
        disabled={form.formState.isSubmitting}
      >
        Submit
      </Button>
    </>
  );
}

const schema = yup.object({
  password: yup
    .string()
    .trim()
    .required()
    .min(8, "Password should be at least 8 characters.")
    .max(10, "Password should be at most 10 characters.")
    .label("Password"),
  confirmPassword: yup
    .string()
    .trim()
    .required()
    .oneOf([yup.ref("password")], "Passwords do not match.")
    .label("Confirm Password"),
});

export default PasswordResetStep;
export { schema };
