import { Container, Typography } from "@material-ui/core";
import React, { useEffect, useState, useContext } from "react";

import ReactGA from "react-ga4";

import "../css/AtclContainer.css";
// import Axios from "./../components/Axios";
import ArticleContext from "../libraries/context2";
import { formatDate } from "../util/helper";
// import draftToHtml from "draftjs-to-html";

function AtclContainer() {
  const context = useContext(ArticleContext);
  const [currentArticle, setCurrentArticle] = useState(context?.currentArticle);
  const [artical, setArtical] = useState();

  useEffect(() => {
    if (context?.currentArticle) {
      const { categoryName, title } = context.currentArticle;
      try {
        ReactGA.event({
          action: `${categoryName}-${title}`,
          category: `${categoryName}-${title}`,
        });
        ReactGA.send({
          hitType: "pageview",
          page: "/article",
          title: `${categoryName}-${title}`,
        });
      } catch (error) {
        console.error(error);
      }
    }
  }, [context.currentArticle]);

  useEffect(() => {
    try {
      let artical1 = window.localStorage.getItem("cArt");
      if (artical1 != null) {
        const newPayloads = JSON.parse(artical1);
        setCurrentArticle(newPayloads);
        const newPara = newPayloads?.pharagraph03;
        window.document.querySelector("#insertArticle").innerHTML = newPara;
        let AllParagraphs =
          window.document.querySelectorAll(`#insertArticle p`);
        AllParagraphs.forEach((item) => {
          item.style.width = "100%";
          item.style.lineHeight = "1.5";
          let i = item.querySelectorAll("span");
          if (i) {
            i.forEach((u) => {
              u.style.lineHeight = "1.5";
              // u.style.fontSize = "12pt";
              u.style.fontFamily = "Arial, Helvetica, sans-serif";
            });
          } else {
            item.style.lineHeight = "1.5";
            // item.style.fontSize = "12pt";
            item.style.fontFamily = "Arial, Helvetica, sans-serif";
          }
        });
      }
    } catch (error) {}
  }, [artical]);
  // useEffect(() => {
  //   // console.log("-----------------------------------", artical);
  //   if (artical?.length !== 0) {
  //     artical?.map((item) => {
  //       if (window.document.querySelector(`#${item?.pID}`)) {
  //         window.document.querySelector(`#${item?.pID}`).innerHTML =
  //           item?.para1;
  //       }
  //       let AllParagraphs = window.document.querySelectorAll("div .para p");
  //       AllParagraphs.forEach((item) => {
  //         item.style.width = `100%`;
  //         item.style.padding = `10px`;
  //       });
  //     });
  //     let resizeImageMobileView = window.document
  //       .querySelector("div .para")
  //       .querySelectorAll("img");
  //     resizeImageMobileView.forEach((item) => {
  //       // item.style.width = `100%`;
  //       item.style.maxWidth = "500px";
  //       item.style.objectFit = "contain";
  //       item.style.marginTop = "10px";
  //       item.style.marginBottom = "10px";
  //     });
  //   }
  // }, [artical]);
  // useEffect(() => {
  //   FetchArticle();
  // }, []);
  useEffect(() => {
    let removeBackgroundColorSpan = window.document
      .querySelector("div .para")
      .querySelectorAll("span");
    removeBackgroundColorSpan.forEach((item) => {
      item.style.backgroundColor = "white";
    });
    let resizeImageMobileVieww = window.document
      .querySelector("div .para")
      .querySelectorAll("figure");
    resizeImageMobileVieww.forEach((item) => {
      item.style.display = `grid`;
      item.style.placeItems = "center";
    });
    let resizeImageMobileView = window.document
      .querySelector("div .para")
      .querySelectorAll("img");
    resizeImageMobileView.forEach((item) => {
      item.style.maxWidth = `900px`;
    });
    // window.document.querySelector("div .para").style.width = `${
    //   window.screen.width - 20
    // }px`;
    // if (window.screen.width <= 430) {
    // }
  }, [window.screen.width]);

  // const FetchArticle = () => {
  //   Axios({
  //     query: `query {
  //       articals {
  //         id
  //         categoryId
  //         categoryName
  //         slotId
  //         title
  //         subTitle
  //         editer
  //         volume
  //         main
  //         slotNo
  //         homePlace
  //         titleImage
  //         pharagraph01
  //         pharagraph02
  //         pharagraph03
  //         createdAt
  //         updatedAt
  //       }
  //     }
  //     `,
  //     title: "articals",
  //   }).then((res) => {
  //     const newFirst = res?.data;
  //     console.log("Updateing", newFirst);
  //     if (newFirst?.articals !== null) {
  //       const filterArticle = newFirst?.articals?.filter(
  //         (item) => item?.main === true
  //       );
  //       let count = 0;

  //       const updateArticals = filterArticle?.map((item) => {
  //         count++;
  //         return {
  //           category: item?.categoryName,
  //           image: item?.titleImage,
  //           heading: item?.title,
  //           editor: item?.editer,
  //           sub: item?.subTitle,
  //           para1: item?.pharagraph01,
  //           slotNo: item?.slotNo,
  //           place: item?.homePlace,
  //           all: item,
  //           pID: `morePa${count}`,
  //         };
  //       });
  //       setArtical(
  //         updateArticals?.sort(
  //           (a, b) => parseInt(a?.slotNo) - parseInt(b?.slotNo)
  //         )
  //       );
  //     } else {
  //       console.error(
  //         `Errors articals on ${JSON.stringify(newFirst?.errors[0]?.message)}`
  //       );
  //     }
  //   });
  // };

  return (
    <Container maxWidth="md">
      <div className="atclcontainer">
        <div className="imgAndText">
          <div className="imageDiv">
            <div className="image" />
            <div className="gradient"></div>
          </div>
          <span
            className="image_text"
            style={{
              width: "fit-content",
              padding: 0,
              maxWidth: 900,
              textAlign: "center",
            }}
          >
            {currentArticle?.title}
          </span>
        </div>
        <div className="containerAd__para">
          <div className="container_para">
            <p className="para_heading">{currentArticle?.categoryName}</p>
            <p className="para_date">Edited by : {currentArticle?.editer}</p>

            <p className="para_date">
              Updated{" "}
              {currentArticle?.updatedAt &&
                formatDate(new Date(currentArticle.updatedAt))}
            </p>

            <div
              className="para"
              style={{ marginTop: 20 }}
              id="insertArticle"
            ></div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default AtclContainer;
