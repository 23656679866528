import React, { useEffect, useState } from "react";

import * as Slots from "../Config/Slots";
import "../css/HomeBodyBottom.css";
import { SlotsWithArticleQuery } from "../server/query";
import Axios from "./Axios";
import BodyContent from "./BodyContent";
import Login from "./Login";
import Register from "./Register";

function HomeBodyBottom() {
  const [article, setArticle] = useState();
  const [visibleReg, setVisibleReg] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  useEffect(() => {
    FetchArticle();
  }, []);
  useEffect(() => {
    if (article?.length !== 0) {
      article?.map((item) => {
        window.document.querySelector(`#${item?.p}`).innerHTML = item?.para;
        let AllParagraphs = window.document.querySelectorAll(`#${item?.p} p`);

        AllParagraphs.forEach((item1) => {
          if (item1.querySelector("span")) {
            item1.querySelector("span").style.lineHeight = "1.5";
            // item1.querySelector("span").style.fontSize = "12pt";
            item1.querySelector("span").style.fontFamily =
              "Arial, Helvetica, sans-serif";
          } else {
            item1.style.lineHeight = "1.5";
            // item1.style.fontSize = "12pt";
            item1.style.fontFamily = "Arial, Helvetica, sans-serif";
          }
        });
      });
    }
  }, [article]);
  const FetchArticle = () => {
    Axios({ query: SlotsWithArticleQuery, title: "articles" }).then((res) => {
      const newFirst = res?.data;
      if (newFirst?.slots !== null) {
        const filterArticle = newFirst?.slots?.filter(
          (item) =>
            item?.artical?.main === true &&
            item?.place === "THIRD" &&
            item?.artical?.homePlace === "THIRD" &&
            (item.slot === Slots?.thirdSlot1 ||
              item.slot === Slots?.thirdSlot2 ||
              item.slot === Slots?.thirdSlot3 ||
              item.slot === Slots?.thirdSlot4 ||
              item.slot === Slots?.thirdSlot5 ||
              item.slot === Slots?.thirdSlot6 ||
              item.slot === Slots?.thirdSlot7),
        );
        const updateSlots = filterArticle?.sort(
          (a, b) => parseInt(a?.slot) - parseInt(b?.slot),
        );
        let count = 0;
        const updateArticals = updateSlots?.map((item) => {
          const para = item?.artical?.pharagraph01;
          count++;
          return {
            category: item?.artical?.categoryName,
            image: item?.artical?.titleImage,
            topic: item?.artical?.title,
            sub: item?.artical?.subTitle,
            para: para,
            slotNo: item?.artical?.slotNo,
            place: item?.artical?.homePlace,
            all: item?.artical,
            p: `thirdPara${count}`,
          };
        });

        setArticle(updateArticals);
      } else {
        console.error(
          `Errors articles on ${JSON.stringify(newFirst?.errors[0]?.message)}`,
        );
      }
    });
  };
  return (
    <>
      <div className="homeBodyBottom">
        <div className="bodyContentRow">
          {article?.map((item) => {
            return <BodyContent payload={item} />;
          })}
        </div>
      </div>
      <Register
        open={visibleReg}
        onClose={() => setVisibleReg(false)}
        openLoginModal={() => setIsLoginModalOpen(true)}
      />
      <Login
        open={isLoginModalOpen}
        openModal={() => setIsLoginModalOpen(true)}
        onClose={() => setIsLoginModalOpen(false)}
        openRegisterModal={() => setVisibleReg(true)}
      />
    </>
  );
}

export default HomeBodyBottom;
