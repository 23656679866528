import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import reducer, { initialState } from './libraries/reducer';
import { StateProvider } from './libraries/stateProvider';

import './index.css';

import { hot } from 'react-hot-loader/root';

const WithHotReload = process.env.NODE_ENV === 'production' ? App : hot(App);

ReactDOM.render(
  <React.StrictMode>
    <StateProvider initialState={initialState} reducer={reducer}> 
      <WithHotReload />
    </StateProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
