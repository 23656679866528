import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import React, { useEffect } from "react";

import {
  Controller,
  useFormContext as useHookFormContext,
} from "react-hook-form";

import * as yup from "yup";

import PasswordField from "../password-field";

import { useFormContext } from "./formContext";

function PersonalInformationStep() {
  const form = useHookFormContext();

  const { setTitle, setDialogMaxWidth } = useFormContext();

  useEffect(() => {
    setTitle("Register");
    setDialogMaxWidth("sm");
  }, []);

  return (
    <div className="register__container" id="register__container">
      <Box
        display="grid"
        gridTemplateColumns={{ md: "repeat(2,1fr)" }}
        columnGap={4}
        rowGap={2}
      >
        <label className="inputTextRegister">
          First Name
          <Controller
            control={form.control}
            name="firstName"
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                fullWidth
                size="small"
                spellCheck="false"
                type="text"
                placeholder="Sam"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                inputRef={ref}
                {...field}
              />
            )}
          />
        </label>

        <label className="inputTextRegister">
          Last Name
          <Controller
            control={form.control}
            name="lastName"
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                fullWidth
                size="small"
                spellCheck="false"
                type="text"
                placeholder="Tyson"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                inputRef={ref}
                {...field}
              />
            )}
          />
        </label>

        <label className="inputTextRegister">
          Mobile Number
          <Controller
            control={form.control}
            name="mobileNumber"
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                type="text"
                fullWidth
                size="small"
                inputMode="tel"
                placeholder="+94xxxxxxxxx"
                autoComplete="tel"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                inputRef={ref}
                {...field}
              />
            )}
          />
        </label>

        <label className="inputTextRegister">
          Email
          <Controller
            control={form.control}
            name="email"
            render={({ field: { value } }) => (
              <TextField fullWidth size="small" value={value} disabled={true} />
            )}
          />
        </label>

        <label className="inputTextRegister">
          Education
          <Controller
            control={form.control}
            name="education"
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                type="text"
                fullWidth
                size="small"
                spellCheck="false"
                placeholder="AL/HND/BSC/MASTER/OTHER"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                inputRef={ref}
                {...field}
              />
            )}
          />
        </label>
        <label className="inputTextRegister">
          Occupation
          <Controller
            control={form.control}
            name="occupation"
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                type="text"
                fullWidth
                size="small"
                spellCheck="false"
                placeholder="Software Engineer"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                inputRef={ref}
                {...field}
              />
            )}
          />
        </label>

        <label className="inputTextRegister">
          Password
          <Controller
            control={form.control}
            name="password"
            render={({ field: { ref, ...field }, fieldState }) => (
              <PasswordField
                fullWidth
                size="small"
                autoComplete="new-password"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                inputRef={ref}
                {...field}
              />
            )}
          />
        </label>

        <label className="inputTextRegister">
          Confirm Password
          <Controller
            control={form.control}
            name="confirmPassword"
            render={({ field: { ref, ...field }, fieldState }) => (
              <PasswordField
                fullWidth
                size="small"
                autoComplete="new-password"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                inputRef={ref}
                {...field}
              />
            )}
          />
        </label>

        <label className="inputTextRegister" style={{ gridColumn: "span 2" }}>
          Address
          <Controller
            control={form.control}
            name="address"
            render={({ field: { ref, ...field }, fieldState }) => (
              <TextField
                multiline
                rows={2}
                fullWidth
                size="small"
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                inputRef={ref}
                {...field}
              />
            )}
          />
        </label>
      </Box>

      <Controller
        control={form.control}
        name="isSubscribed"
        render={({ field: { value, ...field } }) => (
          <FormControlLabel
            sx={{ my: 1 }}
            label={
              <Typography variant="caption" sx={{ userSelect: "none" }}>
                You agree to receive updates from Respect. You may opt out
                anytime.
              </Typography>
            }
            control={
              <Checkbox
                size="small"
                {...field}
                checked={Boolean(value)}
                inputRef={field.ref}
                id="newsletter"
              />
            }
          />
        )}
      />

      <Button
        sx={{ width: 1 / 2, display: "table", mx: "auto" }}
        variant="contained"
        type="submit"
        color="primary"
        disabled={form.formState.isSubmitting}
      >
        Create Account
      </Button>
    </div>
  );
}

const schema = yup.object({
  firstName: yup.string().trim().required().label("First Name"),
  lastName: yup.string().trim().required().label("Last Name"),
  education: yup.string().trim().required().label("Education"),
  occupation: yup.string().trim().required().label("Occupation"),
  isSubscribed: yup.boolean().required().label("Newsletter"),
  mobileNumber: yup
    .string()
    .trim()
    .required()
    .label("Mobile Number")
    .matches(
      /^\+[1-9]\d{10,14}$/,
      "Invalid Mobile Number format eg:+947XXXXXXXX",
    ),
  address: yup
    .string()
    .trim()
    .label("Address")
    .nullable()
    .defined()
    .transform((value) => value || null),
  password: yup.string().min(6).required().label("Password"),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref("password")], "Password do not match.")
    .label("Confirm Password"),
});

export default PersonalInformationStep;
export { schema };
