import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { Container, useMediaQuery } from "@mui/material";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

import React, { forwardRef, useState } from "react";
import { useHistory } from "react-router-dom";

import RegisterBox from "./RegisterBox";

import WithComponent from "../WithParent";

import respectLogoRed from "../../images/logoRed.png";

import "../../css/HomeFooter.css";

const HomeFooter = forwardRef(({ setSwtDic }, ref) => {
	const isMdDown = useMediaQuery(({ breakpoints }) => breakpoints.down("md"));
	const history = useHistory();

	const [isCollapsibleOpen, setIsCollapsibleOpen] = useState({
		quickAccess: false,
		contact: false,
	});

	function openDisclaimer() {
		window.scroll(0, 0);
		setSwtDic(true);
	}

	function navigateToHome() {
		history?.push("/");
	}

	function toggleCollapsible(/** @type {keyof isCollapsibleOpen} */ name) {
		setIsCollapsibleOpen((current) => ({ ...current, [name]: !current[name] }));
	}

	const getCollapsibleIcon = (/** @type {boolean} */ open) =>
		open ? ExpandLessIcon : ExpandMoreIcon;

	return (
		<Box bgcolor={(theme) => theme.palette.grey["200"]} ref={ref}>
			<Container maxWidth="xl" component="footer" disableGutters>
				<Box
					px={{ xs: 3, md: 4 }}
					py={{ xs: 3, md: 4 }}
					display="grid"
					gridTemplateColumns={{ xs: "auto", md: "1fr auto" }}
					justifyContent={{ xs: "space-around", md: "space-between" }}
					gap={{ xs: 3, md: 6 }}
					alignItems="center"
				>
					<Box
						display="grid"
						gridTemplateColumns={{ md: "auto auto auto" }}
						gap={{ xs: 1.5, md: 3 }}
					>
						<Box
							component="img"
							alt="logo"
							src={respectLogoRed}
							width={200}
							height={80}
							justifySelf="center"
							onClick={navigateToHome}
						/>
						<section>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography
									variant="h6"
									component="h2"
									className="font-mont"
									fontWeight={600}
									sx={{
										textDecoration: {
											md: "underline",
											textUnderlineOffset: ".5rem",
										},
									}}
								>
									Quick Access
								</Typography>
								{isMdDown && (
									<IconButton onClick={() => toggleCollapsible("quickAccess")}>
										<Box
											component={getCollapsibleIcon(
												isCollapsibleOpen.quickAccess,
											)}
											fontSize="32px"
										/>
									</IconButton>
								)}
							</Box>
							<WithComponent
								component={Collapse}
								condition={isMdDown}
								in={isCollapsibleOpen.quickAccess}
							>
								<List disablePadding={isMdDown}>
									<ListItem disableGutters disablePadding>
										<ListItemButton
											onClick={openDisclaimer}
											sx={{ borderRadius: 1.5 }}
										>
											<Typography
												color="black"
												className="font-mont"
												fontWeight={500}
											>
												Disclaimer
											</Typography>
										</ListItemButton>
									</ListItem>
								</List>
							</WithComponent>
						</section>

						<section>
							<Box
								display="flex"
								alignItems="center"
								justifyContent="space-between"
							>
								<Typography
									variant="h6"
									component="h2"
									fontWeight={600}
									className="font-mont"
									sx={{
										textDecoration: {
											md: "underline",
											textUnderlineOffset: ".5rem",
										},
									}}
								>
									Contact Us
								</Typography>
								{isMdDown && (
									<IconButton onClick={() => toggleCollapsible("contact")}>
										<Box
											component={getCollapsibleIcon(isCollapsibleOpen.contact)}
											fontSize="32px"
										/>
									</IconButton>
								)}
							</Box>
							<WithComponent
								component={Collapse}
								condition={isMdDown}
								in={isCollapsibleOpen.contact}
							>
								<List disablePadding={isMdDown}>
									<ListItem disableGutters disablePadding>
										<ListItemButton
											component="a"
											href="mailto:respectsrilanka@gmail.com"
											sx={{ borderRadius: 1.5 }}
										>
											<Typography
												color="black"
												className="font-mont"
												fontWeight={500}
											>
												Email
											</Typography>
										</ListItemButton>
									</ListItem>
								</List>
							</WithComponent>
						</section>
					</Box>

					<RegisterBox />
				</Box>
				<Divider />
				<Typography
					variant="body1"
					py={1}
					px={{ xs: 3, md: 4 }}
					textAlign={{ xs: "center", md: "initial" }}
					className="font-mont"
				>
					&copy; Dottech Software {getCurrentYear()}.
				</Typography>
			</Container>
		</Box>
	);
});

HomeFooter.displayName = "HomeFooter";

const getCurrentYear = () => new Date().getUTCFullYear();

export default HomeFooter;
