import { NavLink } from 'react-router-dom';

import Link from '@mui/material/Link';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';

function ListItemLink({ to, icon: Icon, children, activeStyle, sx }) {
  function isActiveLink(params) {
    if (params === null) return false;
    const pathname = params.path.split('/').at(-1);
    const isActive = pathname === to;
    return isActive;
  }

  return (
    <ListItem disablePadding>
      <Link
        to={to}
        component={NavLink}
        isActive={isActiveLink}
        activeStyle={{ borderRadius: '.25em', ...activeStyle }}
        sx={{ textDecoration: 'none', color: 'unset', width: '100%', ...sx }}
      >
        <ListItemButton>
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText>{children}</ListItemText>
        </ListItemButton>
      </Link>
    </ListItem>
  );
}

export default ListItemLink;
