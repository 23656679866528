import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import OTPInput from "react-otp-input";

import * as yup from "yup";

function EmailConfirmationStep() {
  const form = useFormContext();

  function handleChange(name, value) {
    form.setValue(name, value, { shouldValidate: true });
  }

  return (
    <>
      <FormControl sx={{ mb: 1 }}>
        <Box component="label" mb={0.5}>
          Verification Code
        </Box>
        <Controller
          name="verificationCode"
          render={({ field: { value, name }, fieldState }) => (
            <OTPInput
              value={value}
              containerStyle={{ gap: ".5em" }}
              onChange={(value) => handleChange(name, value)}
              shouldAutoFocus
              inputType="tel"
              numInputs={5}
              inputStyle={{ width: "72px", height: "72px" }}
              renderInput={({ ref, ...props }) => (
                <TextField
                  error={Boolean(fieldState.error)}
                  inputRef={ref}
                  inputProps={{
                    style: { textAlign: "center", fontSize: "1.5rem" },
                  }}
                  {...props}
                />
              )}
            />
          )}
        />

        <FormHelperText error={!!form.getFieldState("verificationCode").error}>
          {form.getFieldState("verificationCode").error?.message ??
            "Please check the spam folder too and try again."}
        </FormHelperText>
      </FormControl>

      <Box textAlign="center">
        <Button
          type="submit"
          variant="contained"
          sx={{ width: "50%" }}
          disabled={form.formState.isSubmitting}
        >
          Verify
        </Button>
      </Box>
    </>
  );
}

const schema = yup.object({
  verificationCode: yup
    .string()
    .trim()
    .required()
    .length(5, "Invalid verification code.")
    .label("Verification Code"),
});

export default EmailConfirmationStep;
export { schema };
