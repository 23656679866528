import React, { useContext } from 'react';
import '../css/BodyContent.css';
import { useHistory } from 'react-router-dom';
import ArticleContext from '../libraries/context2';
import { Box, Button } from '@mui/material';

function BodyContent({ payload }) {
  const context = useContext(ArticleContext);
  const history = useHistory();

  const ShowArticleDetails = () => {
    context.setCurrentArticle(payload?.all);
    try {
      window.localStorage.setItem('cArt', JSON.stringify(payload?.all));
    } catch (error) {}
    history.push('/article', payload?.all);
  };

  return (
    <Box className='body_content'>
      <p className='topicBodyContent'>{payload?.topic}</p>
      <p className='headingBodyContent'>{payload?.sub}</p>
      <br />
      <div className='contentOfBodyContent'>
        <p className='content_text' id={payload.p}></p>
      </div>
      {/* <button className='button buttonLearnMore' onClick={() => ShowArticleDetails()}>
        Learn more
      </button> */}
      <Button variant='outlined' onClick={ShowArticleDetails} sx={{ alignSelf: 'start', mb: 2.5 }}>
        Learn more
      </Button>
    </Box>
  );
}

export default BodyContent;
