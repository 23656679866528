import draftToHtml from "draftjs-to-html";
import React, { useEffect } from "react";
import "../css/UpperPara.css";

function UpperPara2({ para, id }) {
  useEffect(() => {
    if (para) {
      if (window.document.querySelector(`#${id}`)) {
        window.document.querySelector(`#${id}`).innerHTML = para;
        let AllParagraphs = window.document.querySelectorAll(`#${id} p`);
        AllParagraphs.forEach((item) => {
          let i = item.querySelector("span");
          item.style.width = "100%";

          if (i) {
            i.style.lineHeight = 1.5;
            // i.style.fontSize = "12pt";
            i.style.fontFamily = "Arial, Helvetica, sans-serif";
          } else {
            item.style.lineHeight = 1.5;
            // item.style.fontSize = "12pt";
            item.style.fontFamily = "Arial, Helvetica, sans-serif";
          }
        });
      }
    }
  }, [para]);

  return (
    <div className="upperTextContainer">
      <p className="containerText1" id={id}></p>
    </div>
  );
}

export default UpperPara2;
